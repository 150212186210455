:root {

  /* colors */
  --background-main: #020826; 
  --background-main-light: #020813;
  --background-main-dark: #020f29;
  --background-secondary: #2772db; 
  --color-secondary: #21BBE8;
  --color-check: #121213;
  --color-main: #f5ebeb; 
  --color-third: #a09191; 
  --color-try: #d2cbcb;
  --color-try-link-hover: #8a8080;

  /* gradient */
  --background-gradient: linear-gradient(90deg, rgba(5,25,84,1) 0%, rgba(10,49,112,1) 100%);
  --phone-mainLogo-bottom-border: linear-gradient(to right, var(--background-main),var(--background-secondary),var(--color-secondary),var(--color-secondary),var(--color-secondary),var(--background-secondary),var(--background-main));

  /* box shadow */
  --shadow-box: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  --shadow-box-secondary: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 20px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 12px, rgba(0, 0, 0, 0.09) 0px -2px 5px;

  --box-shadow-Title: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);

  --box-shadow-input: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.22) 0px 6px 6px;

  --box-shadow-social-icon: rgba(14, 11, 11, 0.4) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px;

  /* text shadow */
  --shadow-text: 0px 4px 2px rgba(0,0,0,0.4),
  0px 8px 12px rgba(0,0,0,0.1),
  0px 18px 22px rgba(0,0,0,0.1);

  --shadow-text-secondary: 0px 0px 7px rgba(24, 0, 0, 1),-2px 4px 4px rgba(26, 20, 59, 1);

  /* clip path */
  --clip-path-polygon: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);

/* text stripes */
--stripe-main: -webkit-linear-gradient(45deg, rgba(0, 0, 0, .2) 25%, transparent 15%, transparent 60%, rgba(0, 0, 0, .2) 80%, rgba(0, 0, 0, .2) 75%, transparent 25%, transparent);

}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: var(--background-main);
}

/* config */

.dfcja{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-main);
  cursor: default;
}

.dfrja{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--color-main);
  cursor: default;
}

a:link, a:visited, a:hover, a:active{
  color: var(--color-main);
  text-decoration: none;
}

input{
  border: none;
}

