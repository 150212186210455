.Footer{
  width: 100%;
  height: 10dvh;
  min-height: 40px;
  justify-content: flex-end;
  padding-bottom: 1rem;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.copyRight{
  font-size: 0.8rem;
  color: var(--color-main);
  opacity: .6;
}

.copyRight > a:link{
  font-family: 'Roboto', sans-serif;
  color: var(--color-main);
  text-decoration: none;
}

.copyRight > a:visited{
  color: var(--color-main);
}

.copyRight > a:hover {
  transition: .5s;
  transform: scale(1.1, 1.1);
  color: var(--color-try-link-hover);
}

.copyRight > a:active{
  color: var(--color-secondary);
}