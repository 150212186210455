.socialPlace{
  justify-content: space-between;
  align-items: center;
}

.socialicons{
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: showSocialIcons;
  animation-fill-mode: forwards;
  animation-duration: 0.6s;
  position: relative;
}

@media (max-width: 480px) {
  
  .socialPlace{
    width: 98%;
    min-width: 350px;
    aspect-ratio: 10/1;
  }

  .socialicons{
    font-size: 0.6rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .socialPlace{
    width: 94%;
    max-width: 640px;
    aspect-ratio: 10/1;
  }

  .socialicons{
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .socialPlace{
    width: 640px;
    aspect-ratio: 10/1;
  }

  .socialicons{
    font-size: 0.8rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
 
  .socialPlace{
    width: 640px;
    aspect-ratio: 10/1;
  }

  .socialicons{
    font-size: 0.8rem;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .socialPlace{
    width: 640px;
    aspect-ratio: 10/1;
  }

  .socialicons{
    font-size: 0.8rem;
  }
}

@media (min-width: 1600px) {

  .socialPlace{
    width: 640px;
    aspect-ratio: 10/1;
  }

  .socialicons{
    font-size: 0.8rem;
  }
}

.socialicons > a:link{
  font-size: 1rem;
  color: var(--color-main);
  text-decoration: none;
  opacity: .8;
}

.socialicons > a:visited{
  color: var(--color-main);
  opacity: 0.8;
}

.socialicons > a:hover {
  transition: .5s;
  transform: scale(1.04, 1.04);
  color: var(--color-try-link-hover);
}

.socialicons > a:active{
  color: var(--color-secondary);
}

