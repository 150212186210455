.Header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 10dvh;
  min-height: 60px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.lockedBtn {
  font-family: 'Carter One', cursive;
  font-size: 0.895rem;
  color: var(--color-try);
  opacity: 0.75;
  padding: 0.5rem 1.4rem;
  cursor: pointer;
  border-radius: 0.6rem;
  border: 2px solid transparent;
  transition-property: background-color, box-shadow;  
  transition: 0.15s;
}

.lockedBtn:hover{
  opacity: 1;
  background: var(--background-main-light);
  background: var(--background-gradient);
  color: var(--color-main);
  box-shadow: var(--shadow-box-secondary);
  border: 2px solid var(--color-secondary);
}

@media (max-width: 480px) {
  
  .lockedBtn {
    margin-right: 1.2rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
 
  .lockedBtn {
    margin-right: 1.8rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .lockedBtn {
    margin-right: 2rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .lockedBtn {
    margin-right: 8rem;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .lockedBtn {
    margin-right: 10rem;
  }
}

@media (min-width: 1600px) {
  
  .lockedBtn {
    margin-right: 10rem;
  }
}

