@media (max-width: 480px) {
  
  .main_logo_dark::before{
    content: "";
    display: flex;
    width: 94%;
    aspect-ratio: 3.2 / 1;
    position: absolute;
    border-bottom: .3rem solid var(--color-secondary);
    border-image: var(--phone-mainLogo-bottom-border);
    border-image-slice: 1;
  }
  
  .logo_Cont{
    width: 100%;
    max-width: 148px;
    padding: 1.4rem;
    border-radius: 10rem;
  }
  
  .logo{
    width: 100%;
  }
  
  .Tiegrun{
    font-size: 54px;
    font-family: 'Carter One', cursive;
    text-shadow: var(--shadow-text);
    background-clip: text;
    color: transparent;
    background-color: var(--color-main);
    background-image: var(--stripe-main);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
 
  .main_logo_dark::before{
    content: "";
    display: flex;
    width: 94%;
    aspect-ratio: 3.2 / 1;
    position: absolute;
    border-bottom: .3rem solid var(--color-secondary);
    border-image: var(--phone-mainLogo-bottom-border);
    border-image-slice: 1;
  }
  
  .logo_Cont{
    width: 100%;
    max-width: 168px;
    padding: 2.2rem;
    border-radius: 10rem;
  }
  
  .logo{
    width: 100%;
  }
  
  .Tiegrun{
    font-size: 72px;
    font-family: 'Carter One', cursive;
    text-shadow: var(--shadow-text);
    background-clip: text;
    color: transparent;
    background-color: var(--color-main);
    background-image: var(--stripe-main);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
 
  .main_logo_dark::before{
    content: "";
    display: flex;
    width: 90%;
    max-width: 800px;
    aspect-ratio: 3.2 / 1;
    position: absolute;
    border-bottom: .3rem solid var(--color-secondary);
    border-image: var(--phone-mainLogo-bottom-border);
    border-image-slice: 1;
  }
  
  .logo_Cont{
    width: 100%;
    max-width: 188px;
    padding: 2.4rem;
    border-radius: 10rem;
  }
  
  .logo{
    width: 100%;
  }
  
  .Tiegrun{
    font-size: 78px;
    font-family: 'Carter One', cursive;
    text-shadow: var(--shadow-text);
    background-clip: text;
    color: transparent;
    background-color: var(--color-main);
    background-image: var(--stripe-main);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .main_logo_dark::before{
    content: "";
    display: flex;
    width: 800px;
    aspect-ratio: 3.2 / 1;
    position: absolute;
    border-bottom: .3rem solid var(--color-secondary);
    border-image: var(--phone-mainLogo-bottom-border);
    border-image-slice: 1;
  }
  
  .logo_Cont{
    width: 100%;
    max-width: 198px;
    padding: 2.4rem;
    border-radius: 10rem;
  }
  
  .logo{
    width: 100%;
  }
  
  .Tiegrun{
    font-size: 96px;
    font-family: 'Carter One', cursive;
    text-shadow: var(--shadow-text);
    background-clip: text;
    color: transparent;
    background-color: var(--color-main);
    background-image: var(--stripe-main);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .main_logo_dark::before{
    content: "";
    display: flex;
    width: 800px;
    aspect-ratio: 3.2 / 1;
    position: absolute;
    border-bottom: .3rem solid var(--color-secondary);
    border-image: var(--phone-mainLogo-bottom-border);
    border-image-slice: 1;
  }
  
  .logo_Cont{
    width: 100%;
    max-width: 198px;
    padding: 2.4rem;
    border-radius: 10rem;
  }
  
  .logo{
    width: 100%;
  }
  
  .Tiegrun{
    font-size: 96px;
    font-family: 'Carter One', cursive;
    text-shadow: var(--shadow-text);
    background-clip: text;
    color: transparent;
    background-color: var(--color-main);
    background-image: var(--stripe-main);
  }
}

@media (min-width: 1600px) {
  
  .main_logo_dark::before{
    content: "";
    display: flex;
    width: 800px;
    aspect-ratio: 3.2 / 1;
    position: absolute;
    border-bottom: .3rem solid var(--color-secondary);
    border-image: var(--phone-mainLogo-bottom-border);
    border-image-slice: 1;
  }
  
  .logo_Cont{
    width: 100%;
    max-width: 198px;
    padding: 2.4rem;
    border-radius: 10rem;
  }
  
  .logo{
    width: 100%;
  }
  
  .Tiegrun{
    font-size: 96px;
    font-family: 'Carter One', cursive;
    text-shadow: var(--shadow-text);
    background-clip: text;
    color: transparent;
    background-color: var(--color-main);
    background-image: var(--stripe-main);
  }
}