@media (max-width: 480px) {
  /* Styles for small smartphones */
}

@media (min-width: 481px) and (max-width: 767px) {
  /* Styles for larger smartphones */
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Styles for portrait tablets */
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Styles for landscape tablets */
}

@media (min-width: 1200px) and (max-width: 1599px) {
  /* Styles for laptops */
}

@media (min-width: 1600px) {
  /* Styles for large desktops */
}

body{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.App{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  min-width: 350px;
  height: 100dvh;
  min-height: 688px;
  position: relative;
}
