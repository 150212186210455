.mainIntro {
  justify-content: center;
  align-items: center;
  color: var(--color-third);
  border: .0009rem solid var(--background-main-dark);
  border-radius: .4rem;
  box-shadow: var(--shadow-box);
  text-shadow: var(--shadow-text-secondary);
  font-weight: 200;
  padding: 1rem;
  margin-top: 1rem;
}

@media (max-width: 480px) {
  
  .mainIntro {
    width: auto;
    height: auto;
    font-size: 1rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .mainIntro {
    width: auto;
    height: auto;
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .mainIntro {
    width: auto;
    height: auto;
    font-size: 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .mainIntro {
    width: auto;
    height: auto;
    font-size: 1rem;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .mainIntro {
    width: auto;
    height: auto;
    font-size: 1rem;
  }
}

@media (min-width: 1600px) {
  
  .mainIntro {
    width: auto;
    height: auto;
    font-size: 1rem;
  }
}

.mainIntro h5{
  text-align: center;
  font-size: .88rem;
}