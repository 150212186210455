.passArea{
  box-shadow: var(--box-shadow-input);
  animation-name: showInputAnimation;
  animation-duration: 0.4s;
}

.noPassAreaAnimation{
  animation-name: hideInputAnimation;
  animation-duration: 0.6s;
}

.pass{
  font-family: 'Carter One', cursive;
  text-align: center; 
  border: none;
  background-color: var(--color-main);;
  color: var(--background-main);
}

.passCorrect{
  font-family: 'Carter One', cursive;
  text-align: center; 
  background-color: var(--color-main);
  color: var(--background-main);
  outline: none;
  font-size: 4rem;
  animation-name: showCorrectPass;
  animation-duration: 0.6s;
}

.pass:focus{
  outline: none;
}

@media (max-width: 480px) {
  
  .passArea{
    width: 100%;
    min-width: 350px;
    aspect-ratio: 8 /1;
  }

  .noPassArea{
    width: 94%;
    aspect-ratio: 8 /1;
  }

  .noPassAreaAnimation{
    width: 94%;
    aspect-ratio: 8 /1;
  }

  .pass{
    width: 94%;
    font-size: 2.8rem;
  }
  
  .passCorrect{
    width: 94%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  
  .passArea{
    width: 94%;
    aspect-ratio: 8 /1;
  }

  .noPassArea{
    width: 94%;
    aspect-ratio: 8 /1;
  }

  .noPassAreaAnimation{
    width: 94%;
    aspect-ratio: 8 /1;
  }

  .pass{
    width: 94%;
    font-size: 3rem;
  }
  
  .passCorrect{
    width: 94%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .passArea{
    width: 94%;
    max-width: 800px;
    aspect-ratio: 8 /1;
  }

  .noPassArea{
    width: 94%;
    max-width: 800px;
    aspect-ratio: 8 /1;
  }

  .noPassAreaAnimation{
    width: 94%;
    max-width: 800px;
    aspect-ratio: 8 /1;
  }

  .pass{
    width: 94%;
    max-width: 800px;
    font-size: 3.2rem;
  }
  
  .passCorrect{
    width: 94%;
    max-width: 800px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  
  .passArea{
    width: 800px;
    aspect-ratio: 8 /1;
  }

  .noPassArea{
    width: 800px;
    aspect-ratio: 8 /1;
  }

  .noPassAreaAnimation{
    width: 800px;
    aspect-ratio: 8 /1;
  }

  .pass{
    width: 800px;
    font-size: 3.4rem;
  }
  
  .passCorrect{
    width: 800px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  
  .passArea{
    width: 800px;
    aspect-ratio: 8 /1;
  }

  .noPassArea{
    width: 800px;
    aspect-ratio: 8 /1;
  }

  .noPassAreaAnimation{
    width: 800px;
    aspect-ratio: 8 /1;
  }

  .pass{
    width: 800px;
    font-size: 4rem;
  }
  
  .passCorrect{
    width: 800px;
  }
}

@media (min-width: 1600px) {
  
  .passArea{
    width: 800px;
    aspect-ratio: 8 /1;
  }

  .noPassArea{
    width: 800px;
    aspect-ratio: 8 /1;
  }

  .noPassAreaAnimation{
    width: 800px;
    aspect-ratio: 8 /1;
  }

  .pass{
    width: 800px;
    font-size: 4rem;
  }
  
  .passCorrect{
    width: 800px;
  }
}
