/* animations */

@keyframes showInputAnimation {
  from {
    transform: scale(0, 0);
    opacity: 0.4;
    border-radius: 0.6rem;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
    border-radius: 0.1rem;
  }
}

@keyframes hideInputAnimation {
  from {
    background: white;
    transform: scale(1, 1);
    opacity: 1;
  }
  to {
    background: none;
    transform: scale(0, 0);
    opacity: 0;
  }
}

@keyframes showCorrectPass {
  from {
    transform: scale(0.9, 0.9);
    opacity: 0.4;
    outline: none;
    border-radius: 0.6rem;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
    outline: none;
    border-radius: 0.1rem;
  }
}

@keyframes showSocialIcons {
  0% {
    transform: scale(0.70, 1);
    box-shadow: var( --box-shadow-social-icon) ;
    opacity: 0;
  }
  50% {
    transform: scale(0.85, 1);
    box-shadow: var( --box-shadow-social-icon) ;
    opacity: 0;
  }
  75% {
    transform: scale(0.95, 1);
    box-shadow: var( --box-shadow-social-icon) ;
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    box-shadow: var( --box-shadow-social-icon) ;
    opacity: 1;
  }
}



